<template>
  <div>
    <div class="title" style="margin-bottom: 20px;">
      <div class="title_t" style="margin-bottom: 0;">
        <span>司机认证审核</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <el-form ref="form" :model="form" label-width="120px">
      <el-card shadow="never">
         <el-row :gutter="20">
            <el-col :span="8">
              <div style="display: flex;align-items: center;justify-content: space-between;">
                <div style="display: flex;align-items: center;">
                  <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                  <span style="font-size: 20px;font-weight: 700;color: #202033;">身份证信息</span>
                </div>
                <!--<div>-->
                <!--  <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>-->
                <!--  <el-button>审核不通过</el-button>-->
                <!--</div>-->
              </div>
              <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
                <el-form-item label="个人姓名:">
                  <el-input
                    clearable
                    v-model="form.id_information.realname"
                    :disabled="true"
                    placeholder="请输入个人姓名"/>
                </el-form-item>
                <el-form-item label="身份证号:">
                  <el-input
                    clearable
                    v-model="form.id_information.id_number"
                    :disabled="true"
                    placeholder="请输入身份证号"/>
                </el-form-item>
                <el-row :gutter="15" style="margin-bottom: 15px;">
                  <el-col :span="12">
                    <el-empty v-if="form.id_information.identification_photo_front == ''" description="暂无"></el-empty>
                    <img v-else :src="form.id_information.identification_photo_front" alt="" style="height: 130px;width: 100%;" v-viewer>
                  </el-col>
                  <el-col :span="12">
                    <el-empty v-if="form.id_information.identification_photo_reverse == ''" description="暂无"></el-empty>
                    <img v-else :src="form.id_information.identification_photo_reverse" alt="" style="height: 130px;width: 100%;" v-viewer>
                  </el-col>
                </el-row>
                <el-form-item label="手机号码:">
                  <el-input
                    clearable
                    v-model="form.id_information.phone"
                    :disabled="true"
                    placeholder="请输入手机号码"/>
                </el-form-item>
              </div>
            </el-col>

           <el-col :span="8">
             <div style="display: flex;align-items: center;justify-content: space-between;">
               <div style="display: flex;align-items: center;">
                 <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                 <span style="font-size: 20px;font-weight: 700;color: #202033;">驾驶证信息</span>
               </div>
               <!--<div>-->
               <!--  <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>-->
               <!--  <el-button>审核不通过</el-button>-->
               <!--</div>-->
             </div>
             <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
               <el-row :gutter="15" style="margin-bottom: 15px;">
                 <el-col :span="12">
                   <el-empty v-if="form.driving_licence.driving_license_front == ''" description="暂无"></el-empty>
                   <img v-else :src="form.driving_licence.driving_license_front" alt="" style="height: 130px;width: 100%;" v-viewer>
                 </el-col>
                 <el-col :span="12">
                   <el-empty v-if="form.driving_licence.driving_license_reverse == ''" description="暂无"></el-empty>
                   <img v-else :src="form.driving_licence.driving_license_reverse" alt="" style="height: 130px;width: 100%;" v-viewer>
                 </el-col>
               </el-row>
             </div>
             <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px;">
               <div style="display: flex;align-items: center;">
                 <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                 <span style="font-size: 20px;font-weight: 700;color: #202033;">行驶证信息</span>
               </div>
               <!--<div>-->
               <!--  <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>-->
               <!--  <el-button>审核不通过</el-button>-->
               <!--</div>-->
             </div>
             <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
               <el-row :gutter="15" style="margin-bottom: 15px;">
                 <el-col :span="12">
                   <el-empty v-if="form.driving_licence.vehicle_license_front == '' || form.driving_licence.vehicle_license_front == undefined" description="暂无"></el-empty>
                   <img v-else :src="form.driving_licence.vehicle_license_front" alt="" style="height: 130px;width: 100%;" v-viewer>
                 </el-col>
                 <el-col :span="12">
                   <el-empty v-if="form.driving_licence.vehicle_license_reverse == '' || form.driving_licence.vehicle_license_reverse == undefined" description="暂无"></el-empty>
                   <img v-else :src="form.driving_licence.vehicle_license_reverse" alt="" style="height: 130px;width: 100%;" v-viewer>
                 </el-col>
               </el-row>
             </div>
           </el-col>

           <el-col :span="8">
             <div style="display: flex;align-items: center;justify-content: space-between;">
               <div style="display: flex;align-items: center;">
                 <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                 <span style="font-size: 20px;font-weight: 700;color: #202033;">车辆信息</span>
               </div>
             </div>
             <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
               <el-form-item label="车牌号:">
                 <el-input
                   clearable
                   v-model="form.vehicle_information.license_plate_number"
                   :disabled="true"
                   placeholder="请输入车牌号"/>
               </el-form-item>
               <el-form-item label="车架号:">
                 <el-input
                   clearable
                   v-model="form.vehicle_information.vin"
                   :disabled="true"
                   placeholder="请输入车架号"/>
               </el-form-item>
               <el-form-item label="车辆类型:">
                 <el-input
                   clearable
                   v-model="form.vehicle_information.vehicle_mold"
                   :disabled="true"
                   placeholder="请输入车辆类型"/>
               </el-form-item>
               <el-form-item label="车辆型号:">
                 <el-input
                   clearable
                   v-model="form.vehicle_information.vehicle_brand"
                   :disabled="true"
                   placeholder="请输入车辆型号"/>
               </el-form-item>
               <el-row :gutter="15" style="margin-bottom: 15px;">
                 <el-col :span="12">
                   <el-empty v-if="form.vehicle_information.front == '' || form.vehicle_information.front == undefined" description="暂无"></el-empty>
                   <img v-else :src="form.vehicle_information.front" alt="" style="height: 130px;width: 100%;" v-viewer>
                 </el-col>
                 <el-col :span="12">
                   <el-empty v-if="form.vehicle_information.side == '' || form.vehicle_information.side == undefined" description="暂无"></el-empty>
                   <img  v-else :src="form.vehicle_information.side" alt="" style="height: 130px;width: 100%;" v-viewer>
                 </el-col>
               </el-row>
             </div>
           </el-col>
         </el-row>

        <el-row :gutter="20">
        <!--  <el-col :span="12">-->
        <!--    <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px;">-->
        <!--      <div style="display: flex;align-items: center;">-->
        <!--        <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
        <!--        <span style="font-size: 20px;font-weight: 700;color: #202033;">保单信息</span>-->
        <!--      </div>-->
        <!--      &lt;!&ndash;<div>&ndash;&gt;-->
        <!--      &lt;!&ndash;  <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>&ndash;&gt;-->
        <!--      &lt;!&ndash;  <el-button>审核不通过</el-button>&ndash;&gt;-->
        <!--      &lt;!&ndash;</div>&ndash;&gt;-->
        <!--    </div>-->
        <!--    <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">-->
        <!--      <el-row :gutter="15" style="margin-bottom: 15px;">-->
        <!--        <el-col :span="8" v-for="(item,index) in form.policy_information" :key="index">-->
        <!--          <el-empty v-if="item.img_dir == ''" description="暂无"></el-empty>-->
        <!--          <img v-else :src="item.img_dir" alt="" style="height: 130px;width: 100%;">-->
        <!--        </el-col>-->
        <!--      </el-row>-->
        <!--    </div>-->
        <!--  </el-col>-->

          <el-col :span="16" style="margin-top: 20px;">
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 10px;">价格信息</span>
                <!--<span style="font-size: 14px;font-weight: 400;color: #4e4e4e;margin-right: 10px;">你已选择：</span>-->
                <!--<span style="font-size: 14px;font-weight: 400;color: #fea837;">重庆市酉阳县</span>-->
              </div>
            </div>
            <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
              <el-row :gutter="15" v-for="(item,index) in form.price_list" :key="index">
                <el-col :span="4">
                  <el-form-item label="项目:">
                    <span>{{item.items_name}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="事故救援一口价:">
                    <el-input
                      clearable
                      v-model="item.accident_price"
                      :disabled="true"
                      placeholder="请输入价格"/>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="故障救援一口价:">
                    <el-input
                      clearable
                      v-model="item.malfunction_price"
                      :disabled="true"
                      placeholder="请输入价格"/>
                  </el-form-item>
                </el-col>
                <el-form-item label="公式价:" v-if="item.billing_model == 'mileage'">
                  <el-input
                    style="display: inline-block; width: 180px;margin-right: 20px;"
                    clearable
                    v-model="item.initiate_price"
                    :disabled="true"
                    placeholder="请输入起步价"/>
                  <el-input
                    style="display: inline-block; width: 180px;margin-right: 20px;"
                    clearable
                    v-model="item.km"
                    :disabled="true"
                    placeholder="请输入公里数"/>
                  <el-input
                    style="display: inline-block; width: 180px;"
                    clearable
                    v-model="item.exceed_km"
                    :disabled="true"
                    placeholder="请输入超出公里价"/>
                </el-form-item>
                <el-form-item label="一口价:" v-if="item.billing_model == 'fixed_price'">
                  <el-input
                    style="display: inline-block; width: 180px;margin-right: 20px;"
                    clearable
                    v-model="item.fixed_price"
                    :disabled="true"
                    placeholder="请输入"/>
                </el-form-item>
                <div style="text-align: center;margin-bottom: 20px;">
                  <el-button @click="butongguo(item,index)">审核不通过</el-button>
                  <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;" @click="tongguo(item,index)">审核通过</el-button>
                </div>
              </el-row>
              <el-empty v-if="form.price_list.length == 0" description="暂无"></el-empty>
              <!--<el-row :gutter="15">-->
              <!--  <el-form-item label="公式价:">-->
              <!--    <el-input-->
              <!--      style="display: inline-block; width: 180px;margin-right: 20px;"-->
              <!--      clearable-->
              <!--      v-model="form.facilitator_name"-->
              <!--      :disabled="true"-->
              <!--      placeholder="请输入起步价"/>-->
              <!--    <el-input-->
              <!--      style="display: inline-block; width: 180px;margin-right: 20px;"-->
              <!--      clearable-->
              <!--      v-model="form.facilitator_name"-->
              <!--      :disabled="true"-->
              <!--      placeholder="请输入公里数"/>-->
              <!--    <el-input-->
              <!--      style="display: inline-block; width: 180px;"-->
              <!--      clearable-->
              <!--      v-model="form.facilitator_name"-->
              <!--      :disabled="true"-->
              <!--      placeholder="请输入超出公里价"/>-->
              <!--  </el-form-item>-->
              <!--</el-row>-->
            </div>
          </el-col>

        </el-row>

      </el-card>

      <!--审核不通过理由-->
      <el-card shadow="never">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 20px;">审核不通过理由</span>
        </div>
        <div style="margin-top: 15px;margin-bottom: 15px;">
          <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8}"
              placeholder="请输入审核不通过的理由"
              v-model="cause">
          </el-input>
        </div>

      </el-card>

    </el-form>

    <!--按钮-->
    <div class="card_bottom">
      <el-button style="width: 240px !important;padding-top:15px;padding-bottom: 15px; background: #F54E71!important;border-color: #F54E71!important;color: #FFFFFF!important;" @click="Failtheaudit">审核不通过</el-button>
      <el-button style="width: 240px !important;padding-top:15px;padding-bottom: 15px;background: #36DD9E!important;border-color: #36DD9E!important;color: #FFFFFF!important;" @click="passtheaudit">审核通过</el-button>
    </div>


    <el-dialog
      title="提示"
      center
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>
        <el-input
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 8}"
          placeholder="请输入审核不通过的理由"
          v-model="cause1">
          </el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="queding()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {check_details, price_check, share_drivershare_check} from "@/api/yunli";

export default {
  name: 'tobecertifiedindex',
  // 支柱道具属性
  props: {
    //数据
    data:Object
  },
  // 组件生效
  components: {},
  // Data数据
  data() {
    return {
      form:{},
      cause:'',

      cause1:'',

      item:{},
      dialogVisible:false,
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取详情
    this.getDetails();
  },
  // 方法集合
  methods: {
    // 获取详情
    getDetails(){
      check_details(this.data.id).then(res => {
        this.form = res.data;
      })
    },

    //返回按钮
    returnPage(){
      this.$emit('showtoexamine',false) ;
    },

    // 价格信息关闭
    handleClose(){
      this.dialogVisible = false;
    },
    //点击审核不通过 价格信息
    butongguo(item,index){
      this.cause1 = '';
      this.item = item;
      this.dialogVisible = true;
    },
    //审核不通过确定 价格信息
    queding(){
      if(this.cause1 === ''){
        this.$message.error('请输入审核不通过理由')
      }else {
        let where = {
          check_status: -1,
          refuse_reason: this.cause1
        }
        price_check(this.item.id,where).then(res => {
          console.log(res);
          if(res.code === 200){
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.getDetails();
          }else {
            this.$message.success(res.msg);
          }
        }).catch(e => {
          this.$message.error(e.msg)
        })
      }
    },
    //审核通过
    tongguo(item,index){
      let where = {
        check_status: 1,
      }
      price_check(item.id,where).then(res => {
        console.log(res);
        if(res.code === 200){
          this.$message.success(res.msg);
          this.dialogVisible = false;
          this.getDetails();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.success(e.msg);
      })
    },


    //审核通过
    passtheaudit(){
      let where = {
        approve_status: 1,
      }
      share_drivershare_check(this.form.id,where).then(res => {
        console.log(res);
        if(res.code === 200){
          this.$message.success(res.msg);
          this.$emit('showtoexamine',false) ;
          this.$emit('dome');
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.success(e.msg);
      })
    },

    //审核不通过
    Failtheaudit(){
      if(this.cause === ''){
        this.$message.error('请输入审核不通过理由')
      }else {
        let where = {
          approve_status: -1,
          refuse_reasons: this.cause
        }
        share_drivershare_check(this.form.id,where).then(res => {
          console.log(res);
          if(res.code === 200){
            this.$message.success(res.msg);
            this.$emit('showtoexamine',false) ;
            this.$emit('dome');
          }else {
            this.$message.success(res.msg);
          }
        }).catch(e => {
          this.$message.error(e.msg)
        })
      }

    },

  },
}
</script>

<style scoped lang="scss">
.span_l{
  display: inline-block;
  width: 128px;
  text-align: right;
}
.span_r{
  font-size: 15px !important;
}
</style>
