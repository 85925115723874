<template>
  <!--待共享认证列表-->
  <div style="padding: 20px">
    <div v-if="showToexAmine == false">
      <div class="title">{{ $route.meta.title }}司机列表</div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="77px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="司机名称:">
                    <el-input
                      clearable
                      v-model="where.realname"
                      placeholder="请输入司机名称"
                    />
                  </el-form-item>
                </el-col>
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="司机状态:">-->
                <!--    <el-select-->
                <!--      clearable-->
                <!--      v-model="where.approve_status"-->
                <!--      placeholder="请选择状态"-->
                <!--      class="ele-fluid">-->
                <!--      <el-option-->
                <!--        v-for="(item) in dict.type.enterprise_status"-->
                <!--        :label="item.label"-->
                <!--        :value="item.value"-->
                <!--      />-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="启用状态:">-->
                <!--    <el-select-->
                <!--      clearable-->
                <!--      v-model="where.status"-->
                <!--      placeholder="请选择状态"-->
                <!--      class="ele-fluid">-->
                <!--      <el-option-->
                <!--        v-for="(item) in dict.type.enable_status"-->
                <!--        :label="item.label"-->
                <!--        :value="item.value"-->
                <!--      />-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>
            </div>
            <!--<div>-->
            <!--  <el-button>导出数据</el-button>-->
            <!--  <el-button @click="ListSetup()">列表设置</el-button>-->
            <!--</div>-->
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:approve_status_label="{ scope }">
                <span v-if="scope.row.approve_status_label == 0">
                  审核未通过
                </span>
                <span v-else> 审核通过 </span>
              </template>
              <template v-slot:operationSlot="{ scope }">
                <el-link
                  v-if="
                    scope.row.approve_status == -1 ||
                    scope.row.approve_status == 0
                  "
                  :underline="false"
                  type="warning"
                  @click="personToexamine(scope.row)"
                  v-auths="[`${$config.uniquePrefix}yunli:tobecertified:put`]"
                  >认证审核</el-link
                >
                <el-link
                  :underline="false"
                  type="warning"
                  v-auths="[`${$config.uniquePrefix}yunli:tobecertified:put`]"
                  @click="priceToexamine(scope.row)"
                  >价格审核</el-link
                >
                <el-link
                  v-if="scope.row.vehicle_check"
                  :underline="false"
                  type="warning"
                  v-auths="[`${$config.uniquePrefix}yunli:tobecertified:put`]"
                  @click="carToexamine(scope.row)"
                  >车辆审核</el-link
                >
              </template>
            </my-table>
          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose"
        >
          <span>
            <PackageSortElement
              :AllParameters="columns"
              @change="SortList"
              @show="dialogshow"
            ></PackageSortElement>
          </span>
        </el-dialog>
      </div>
    </div>

    <!--认证审核-->
    <ToexAmine
      v-if="showToexAmine == true"
      :data="information"
      @showtoexamine="ShowToexAmine"
      @dome="getList"
    />
    <!-- 身份认证 -->
    <el-dialog
      title="身份认证审核"
      :visible.sync="personDialogVisible"
      top="5vh"
      :close-on-click-modal="false"
      width="30%"
      center
      @close="cheliang_check_close"
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-row :gutter="15">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="display: flex; align-items: center">
              <span
                style="
                  width: 5px;
                  height: 19px;
                  background: #ff9b05;
                  display: inline-block;
                  margin-right: 10px;
                "
              ></span>
              <span style="font-size: 20px; font-weight: 700; color: #202033"
                >身份证信息</span
              >
            </div>
            <!--<div>-->
            <!--  <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>-->
            <!--  <el-button>审核不通过</el-button>-->
            <!--</div>-->
          </div>
          <div
            style="
              margin-top: 15px;
              border: 1px solid #e3e4e9;
              border-radius: 6px;
              padding: 20px;
            "
          >
            <el-form-item label="个人姓名:">
              <el-input
                clearable
                v-model="form.id_information.realname"
                :disabled="true"
                placeholder="请输入个人姓名"
              />
            </el-form-item>
            <el-form-item label="身份证号:">
              <el-input
                clearable
                v-model="form.id_information.id_number"
                :disabled="true"
                placeholder="请输入身份证号"
              />
            </el-form-item>
            <el-row :gutter="15" style="margin-bottom: 15px">
              <el-col :span="12">
                <el-empty
                  v-if="form.id_information.identification_photo_front == ''"
                  description="暂无"
                ></el-empty>
                <img
                  v-else
                  :src="form.id_information.identification_photo_front"
                  alt=""
                  style="height: 150px; width: 100%"
                  v-viewer
                />
              </el-col>
              <el-col :span="12">
                <el-empty
                  v-if="form.id_information.identification_photo_reverse == ''"
                  description="暂无"
                ></el-empty>
                <img
                  v-else
                  :src="form.id_information.identification_photo_reverse"
                  alt=""
                  style="height: 150px; width: 100%"
                  v-viewer
                />
              </el-col>
            </el-row>
            <el-form-item label="手机号码:">
              <el-input
                clearable
                v-model="form.id_information.phone"
                :disabled="true"
                placeholder="请输入手机号码"
              />
            </el-form-item>
          </div>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="24">
            <el-card shadow="never">
              <div style="display: flex; align-items: center">
                <span
                  style="
                    width: 5px;
                    height: 19px;
                    background: #ff9b05;
                    display: inline-block;
                    margin-right: 10px;
                  "
                ></span>
                <span
                  style="
                    font-size: 20px;
                    font-weight: 700;
                    color: #202033;
                    margin-right: 20px;
                  "
                  >审核不通过理由</span
                >
              </div>
              <div style="margin-top: 15px; margin-bottom: 15px">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 8 }"
                  placeholder="请输入审核不通过的理由"
                  v-model="cause2"
                >
                </el-input>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="u_personrenzhen">审核不通过</el-button>
        <el-button type="primary" @click="personrenzhen">审核通过</el-button>
      </span>
    </el-dialog>
    <!-- 价格认证审核弹窗 -->
    <el-dialog
      title="价格认证审核"
      :visible.sync="priceDialogVisible"
      top="5vh"
      :close-on-click-modal="false"
      width="50%"
      center
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-row :gutter="15">
          <el-col :span="30" style="margin-top: 20px">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex; align-items: center">
                <span
                  style="
                    width: 5px;
                    height: 19px;
                    background: #ff9b05;
                    display: inline-block;
                    margin-right: 10px;
                  "
                ></span>
                <span
                  style="
                    font-size: 20px;
                    font-weight: 700;
                    color: #202033;
                    margin-right: 10px;
                  "
                  >价格信息</span
                >
                <!--<span style="font-size: 14px;font-weight: 400;color: #4e4e4e;margin-right: 10px;">你已选择：</span>-->
                <!--<span style="font-size: 14px;font-weight: 400;color: #fea837;">重庆市酉阳县</span>-->
              </div>
            </div>
            <div
              style="
                margin-top: 15px;
                border: 1px solid #e3e4e9;
                border-radius: 6px;
                padding: 20px;
              "
            >
              <el-row
                :gutter="15"
                v-for="(item, index) in form.price_list"
                :key="index"
              >
                <el-row>
                  <el-col :span="4">
                    <el-form-item label="项目:" label-width="50px">
                      <span class="w-20">{{ item.items_name }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="15">
                <el-col :span="10">
                  <el-form-item label="事故救援一口价:" >
                    <el-input
                      clearable
                      v-model="item.accident_price"
                      :disabled="true"
                      placeholder="请输入价格"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="故障救援一口价:">
                    <el-input
                      clearable
                      v-model="item.malfunction_price"
                      :disabled="true"
                      placeholder="请输入价格"
                    />
                  </el-form-item>
                </el-col>
                </el-row>
                <el-form-item
                  label="公式价:"
                  v-if="item.billing_model == 'mileage'"
                >
                  <el-input
                    style="
                      display: inline-block;
                      width: 180px;
                      margin-right: 20px;
                    "
                    clearable
                    v-model="item.initiate_price"
                    :disabled="true"
                    placeholder="请输入起步价"
                  />
                  <el-input
                    style="
                      display: inline-block;
                      width: 180px;
                      margin-right: 20px;
                    "
                    clearable
                    v-model="item.km"
                    :disabled="true"
                    placeholder="请输入公里数"
                  />
                  <el-input
                    style="display: inline-block; width: 180px"
                    clearable
                    v-model="item.exceed_km"
                    :disabled="true"
                    placeholder="请输入超出公里价"
                  />
                </el-form-item>

                <el-form-item
                  label="一口价:"
                  v-if="item.billing_model == 'fixed_price'"
                >
                  <el-input
                    style="
                      display: inline-block;
                      width: 180px;
                      margin-right: 20px;
                    "
                    clearable
                    v-model="item.fixed_price"
                    :disabled="true"
                    placeholder="请输入"
                  />
                </el-form-item>
                <div style="text-align: center; margin-bottom: 20px">
                  <el-button @click="butongguo(item, index)"
                    >审核不通过</el-button
                  >
                  <el-button
                    style="
                      background: #202033 !important;
                      border-color: #202033 !important;
                      color: #ffffff !important;
                    "
                    @click="tongguo(item, index)"
                    >审核通过</el-button
                  >
                </div>
              </el-row>
              <el-empty
                v-if="form.price_list.length == 0"
                description="暂无"
              ></el-empty>
              <!--<el-row :gutter="15">-->
              <!--  <el-form-item label="公式价:">-->
              <!--    <el-input-->
              <!--      style="display: inline-block; width: 180px;margin-right: 20px;"-->
              <!--      clearable-->
              <!--      v-model="form.facilitator_name"-->
              <!--      :disabled="true"-->
              <!--      placeholder="请输入起步价"/>-->
              <!--    <el-input-->
              <!--      style="display: inline-block; width: 180px;margin-right: 20px;"-->
              <!--      clearable-->
              <!--      v-model="form.facilitator_name"-->
              <!--      :disabled="true"-->
              <!--      placeholder="请输入公里数"/>-->
              <!--    <el-input-->
              <!--      style="display: inline-block; width: 180px;"-->
              <!--      clearable-->
              <!--      v-model="form.facilitator_name"-->
              <!--      :disabled="true"-->
              <!--      placeholder="请输入超出公里价"/>-->
              <!--  </el-form-item>-->
              <!--</el-row>-->
            </div>
          </el-col>
        </el-row>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer"> -->
      <!-- <el-button @click="priceDialogVisible = false">审核不通过</el-button>
        <el-button type="primary" @click="priceDialogVisible = false"
          >审核通过</el-button
        > -->
      <!-- </span> -->
    </el-dialog>
    <!-- 车辆信息认证弹窗 -->
    <el-dialog
      title="车辆认证审核"
      :visible.sync="carDialogVisible"
      top="5vh"
      :close-on-click-modal="false"
      width="60%"
      center
      @close="cheliang_check_close"
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-row :gutter="15">
          <el-col :span="12">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <!-- <div style="display: flex; align-items: center">
                <span
                  style="
                    width: 5px;
                    height: 19px;
                    background: #ff9b05;
                    display: inline-block;
                    margin-right: 10px;
                  "
                ></span>
                <span style="font-size: 20px; font-weight: 700; color: #202033"
                  >驾驶证信息</span
                >
              </div> -->
              <!--<div>-->
              <!--  <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>-->
              <!--  <el-button>审核不通过</el-button>-->
              <!--</div>-->
            </div>
            <!-- <div
              style="
                margin-top: 15px;
                border: 1px solid #e3e4e9;
                border-radius: 6px;
                padding: 20px;
              "
            >
              <el-row :gutter="15" style="margin-bottom: 15px">
                <el-col :span="12">
                  <el-empty
                    v-if="form.driving_licence.driving_license_front == ''"
                    description="暂无"
                  ></el-empty>
                  <img
                    v-else
                    :src="form.driving_licence.driving_license_front"
                    alt=""
                    style="height: 130px; width: 100%"
                    v-viewer
                  />
                </el-col>
                <el-col :span="12">
                  <el-empty
                    v-if="form.driving_licence.driving_license_reverse == ''"
                    description="暂无"
                  ></el-empty>
                  <img
                    v-else
                    :src="form.driving_licence.driving_license_reverse"
                    alt=""
                    style="height: 130px; width: 100%"
                    v-viewer
                  />
                </el-col>
              </el-row>
            </div> -->
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
              "
            >
              <div style="display: flex; align-items: center">
                <span
                  style="
                    width: 5px;
                    height: 19px;
                    background: #ff9b05;
                    display: inline-block;
                    margin-right: 10px;
                  "
                ></span>
                <span style="font-size: 20px; font-weight: 700; color: #202033"
                  >行驶证信息</span
                >
              </div>
              <!--<div>-->
              <!--  <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>-->
              <!--  <el-button>审核不通过</el-button>-->
              <!--</div>-->
            </div>
            <div
              style="
                margin-top: 15px;
                border: 1px solid #e3e4e9;
                border-radius: 6px;
                padding: 20px;
              "
            >
              <el-row :gutter="15" style="margin-bottom: 15px">
                <el-col :span="12">
                  <el-empty
                    v-if="
                      form.driving_licence.vehicle_license_front == '' ||
                      form.driving_licence.vehicle_license_front == undefined
                    "
                    description="暂无"
                  ></el-empty>
                  <img
                    v-else
                    :src="form.driving_licence.vehicle_license_front"
                    alt=""
                    style="height: 130px; width: 100%"
                    v-viewer
                  />
                </el-col>
                <el-col :span="12">
                  <el-empty
                    v-if="
                      form.driving_licence.vehicle_license_reverse == '' ||
                      form.driving_licence.vehicle_license_reverse == undefined
                    "
                    description="暂无"
                  ></el-empty>
                  <img
                    v-else
                    :src="form.driving_licence.vehicle_license_reverse"
                    alt=""
                    style="height: 130px; width: 100%"
                    v-viewer
                  />
                </el-col>
              </el-row>
            </div>
          </el-col>

          <el-col :span="12">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex; align-items: center">
                <span
                  style="
                    width: 5px;
                    height: 19px;
                    background: #ff9b05;
                    display: inline-block;
                    margin-right: 10px;
                  "
                ></span>
                <span style="font-size: 20px; font-weight: 700; color: #202033"
                  >车辆信息</span
                >
              </div>
            </div>
            <div
              style="
                margin-top: 15px;
                border: 1px solid #e3e4e9;
                border-radius: 6px;
                padding: 20px;
              "
            >
              <el-form-item label="车牌号:">
                <el-input
                  clearable
                  v-model="form.vehicle_information.license_plate_number"
                  :disabled="true"
                  placeholder="请输入车牌号"
                />
              </el-form-item>
              <el-form-item label="车架号:">
                <el-input
                  clearable
                  v-model="form.vehicle_information.vin"
                  :disabled="true"
                  placeholder="请输入车架号"
                />
              </el-form-item>
              <el-form-item label="车辆类型:">
                <el-input
                  clearable
                  v-model="form.vehicle_information.vehicle_mold"
                  :disabled="true"
                  placeholder="请输入车辆类型"
                />
              </el-form-item>
              <el-form-item label="车辆型号:">
                <el-input
                  clearable
                  v-model="form.vehicle_information.vehicle_brand"
                  :disabled="true"
                  placeholder="请输入车辆型号"
                />
              </el-form-item>
              <!-- <el-row :gutter="15" style="margin-bottom: 15px;">
                 <el-col :span="12">
                   <el-empty v-if="form.vehicle_information.bodywork == '' || form.vehicle_information.bodywork == undefined" description="暂无"></el-empty>
                   <img v-else :src="form.vehicle_information.bodywork" alt="" style="height: 130px;width: 100%;" v-viewer>
                 </el-col>
               </el-row> -->
              <el-row :gutter="15" style="margin-bottom: 15px">
                <el-col :span="12">
                  <el-empty
                    v-if="
                      form.vehicle_information.front == '' ||
                      form.vehicle_information.front == undefined
                    "
                    description="暂无"
                  ></el-empty>
                  <img
                    v-else
                    :src="form.vehicle_information.front"
                    alt=""
                    style="height: 130px; width: 100%"
                    v-viewer
                  />
                </el-col>
                <el-col :span="12">
                  <el-empty
                    v-if="
                      form.vehicle_information.side == '' ||
                      form.vehicle_information.side == undefined
                    "
                    description="暂无"
                  ></el-empty>
                  <img
                    v-else
                    :src="form.vehicle_information.side"
                    alt=""
                    style="height: 130px; width: 100%"
                    v-viewer
                  />
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="24">
            <el-card shadow="never">
              <div style="display: flex; align-items: center">
                <span
                  style="
                    width: 5px;
                    height: 19px;
                    background: #ff9b05;
                    display: inline-block;
                    margin-right: 10px;
                  "
                ></span>
                <span
                  style="
                    font-size: 20px;
                    font-weight: 700;
                    color: #202033;
                    margin-right: 20px;
                  "
                  >审核不通过理由</span
                >
              </div>
              <div style="margin-top: 15px; margin-bottom: 15px">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 10, maxRows: 8 }"
                  placeholder="请输入审核不通过的理由"
                  v-model="cause"
                >
                </el-input>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cheliang_checks">审核不通过</el-button>
        <el-button type="primary" @click="cheliang_check">审核通过</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="提示"
      center
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      @close="cheliang_check_close"
    >
      <span>
        <el-input
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 8 }"
          placeholder="请输入审核不通过的理由"
          v-model="cause1"
        >
        </el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="queding()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import { ShowTable } from "@/utils";

import {
  Driver_details,
  Vehicle_certification,
  Drice_details,
  share_drivershare_check,
  price_check,
  Vehicle_certification_renzhen,
} from "@/api/yunli";

//引入司机认证审核
import ToexAmine from "./components/toexamine.vue";

import { getcheck_list } from "@/api/yunli";
import Vue from "vue";
import Template from "../serviceProviderList/details/setUp/components/template.vue";

export default {
  // 组件生效
  components: {
    PackageSortElement,
    ToexAmine,
    Template,
  },
  data() {
    return {
      //信息
      form: {
        vehicle_information: {},
        driving_licence: {},
        id_information: {},
        price_list: [],
      },
      cause1: "",
      cause: "",
      cause2: "",
      item: {},
      //车辆通过审核

      //价格不通过审核理由弹窗
      dialogVisible: false,
      //身份认证
      personDialogVisible: false,
      //价格认证弹窗
      priceDialogVisible: false,
      //车辆认证弹窗
      carDialogVisible: false,
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "系统编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true,
          fixed: "left",
        },
        {
          label: "姓名",
          prop: "realname",
          isShow: true,
        },
        {
          label: "身份证号",
          prop: "id_number",
          isShow: true,
        },
        {
          label: "手机号",
          prop: "phone",
          isShow: true,
        },
        {
          label: "所在地区",
          prop: "serve_district_label",
          isShow: true,
        },
        {
          label: "审核状态",
          prop: "approve_status_label",
          isShow: true,
          // fixed: "center",
        },
        {
          label: "操作",
          width: "300",
          slot: "operationSlot",
          isShow: true,
          fixed: "right",
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,

      //审核组件
      information: null,
      showToexAmine: false,

      //字典
      dict: {
        type: {},
      },

      pid: 0,
    };
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach((item) => {
      if (item.name === "运力字典") {
        item.children.forEach((res) => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        });
      }
    });
    // console.log(this.dict)

    //调用获取客户信息列表方法
    this.getList();
  },

  methods: {
    // 获取列表
    getList() {
      getcheck_list(this.where)
        .then(async (res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        })
        .catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        });
    },

    // 查询条件
    QueryList() {
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val);
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection);
      console.log("选择的所有数据行", selection);
      console.log("当前选择的数据行", row);
    },
    ChangeSize(val) {
      console.log("当前页面显示的条数", val);
      this.where.limit = val;
      this.getList();
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false;
      } else {
        return true;
      }
    },

    //点击认证审核
    Toexamine(row) {
      //显示审核模块
      this.showToexAmine = true;
      this.information = row;
    },
    //接收审核子组件传递的值
    ShowToexAmine(value) {
      this.showToexAmine = value;
    },

    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose() {
      this.dialogVisible = false;
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value) {
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value) {
      this.ListSetupShow = value;
    },
    //价格认证弹窗
    priceToexamine(row) {
      // console.log(row);
      this.form.id = row.id;
      this.priceDialogVisible = true;
      Drice_details(row.id).then((res) => {
        if (res.code == 200) {
          this.form.price_list = res.data;
        }
      });
    },
    //车辆认证弹窗
    carToexamine(row) {
      this.form.id = row.id;
      console.log(row);
      this.carDialogVisible = true;
      Vehicle_certification(row.id).then((res) => {
        console.log(res.data);
        this.form.vehicle_information = res.data;
        this.form.driving_licence = res.data;
      });
    },
    //司机认证
    personToexamine(row) {
      this.form.id = row.id;
      this.personDialogVisible = true;
      Driver_details(row.id).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.form.id_information = res.data.id_information;
          this.form.driving_licence = res.data.driving_licence;
          // console.log(this.form.id_information);
        }
      });
    },
    //身份认证成功
    personrenzhen() {
      let where = {
        approve_status: 1,
      };
      console.log(this.form.id);
      share_drivershare_check(this.form.id, where)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.personDialogVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.msg);
        });
    },
    //身份认证不成功
    u_personrenzhen() {
      // this.personDialogVisible = false;
      if (this.cause2 == "") {
        this.$message.error("请输入审核不通过理由");
        return false;
      }
      let where = {
        approve_status: -1,
        refuse_reasons: this.cause2,
      };
      share_drivershare_check(this.form.id, where)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.cause2 = "";
            this.personDialogVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.msg);
        });
    },
    //价格审核
    tongguo(item, index) {
      let where = {
        check_status: 1,
      };
      price_check(item.id, where)
        .then((res) => {
          if (res.code === 200) {
            // this.$message.success(res.msg);
            Driver_details(this.form.id).then((res) => {
              if (res.code == 200) {
                this.$message.success(res.msg);
                this.form.id_information = res.data.id_information;
                this.form.driving_licence = res.data.driving_licence;
                this.priceDialogVisible = false;
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.msg);
        });
    },
    //价格审核不通过
    butongguo(item, index) {
      this.cause1 = "";
      this.item = item;
      this.dialogVisible = true;
    },

    //价格审核不同过
    queding() {
      if (this.cause1 == "") {
        this.$message.error("请输入审核不通过理由");
      } else {
        let where = {
          check_status: -1,
          refuse_reason: this.cause1,
        };
        price_check(this.item.id, where)
          .then((res) => {
            console.log(res);
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.dialogVisible = false;
              this.cause1 == "";
              Drice_details(this.form.id).then((res) => {
                if (res.code == 200) {
                  this.priceDialogVisible = false;
                  this.form.price_list = res.data;
                }
              });
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((e) => {
            this.$message.error(e.msg);
          });
      }
    },

    //车辆审核通过
    cheliang_check() {
      let where = {
        check_status: 1,
      };
      Vehicle_certification_renzhen(this.form.id, where)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.carDialogVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.msg);
        });
    },

    //车辆审核不通过
    cheliang_checks() {
      if (this.cause == "") {
        this.$message.error("请输入审核不通过理由");
        return false;
      }
      let where = {
        check_status: -1,
        refuse_reasons: this.cause,
      };
      Vehicle_certification_renzhen(this.form.id, where)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.carDialogVisible = false;
            this.cause = "";
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.msg);
        });
    },
    cheliang_check_close() {
      this.cause = "";
      this.cause1 = "";
      this.cause2 = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.w-20 {
  width: 50px;
}
</style>
